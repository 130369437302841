import { DebouncedFunc } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { CONTROL_RESPONSIBILITY } from '@trustero/trustero-api-web/lib/model/control_pb'
import palette from 'src/designSystem/variables/palette'
import { USER_ROLE } from '@trustero/trustero-api-web/lib/account/account_pb'
import { StringMap } from '../../../../Utils/ComplianceFramework/complianceFrameworkUtils'

export type ControlDropdownProps = {
  modelId: string
  frameworkState: StringMap
  setFrameworkState: Dispatch<SetStateAction<StringMap>>
  updateControl: DebouncedFunc<
    (newFrameworkState: StringMap, logEntryValue?: string) => Promise<void>
  >
}

export enum UserRole {
  ADMIN = 'Admin',
  USER = 'User',
  READONLY = 'Read Only',
  AUDITOR = 'Auditor',
}

export const UserRoleOptions: Record<USER_ROLE, string> = Object.freeze({
  [USER_ROLE.ADMIN]: UserRole.ADMIN,
  [USER_ROLE.USER]: UserRole.USER,
  [USER_ROLE.READONLY]: UserRole.READONLY,
  [USER_ROLE.AUDITOR]: UserRole.AUDITOR,
})

export const UserRoleDropdownOptions = [
  {
    key: USER_ROLE.ADMIN,
    label: UserRoleOptions[USER_ROLE.ADMIN],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: USER_ROLE.USER,
    label: UserRoleOptions[USER_ROLE.USER],
    colors: {
      text: palette.neutral.white,
      textHover: palette.blue['50'],
      background: palette.neutral['400'],
      backgroundHover: palette.neutral['900'],
    },
  },
  {
    key: USER_ROLE.READONLY,
    label: UserRoleOptions[USER_ROLE.READONLY],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: USER_ROLE.AUDITOR,
    label: UserRoleOptions[USER_ROLE.AUDITOR],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
]

export enum ControlResponsibility {
  DIRECT_RESPONSIBILITY = 'Direct Responsibility',
  OUTSOURCED_NO_ATTESTATION = 'Outsourced - No Attestation',
  OUTSOURCED_ATTESTATION = 'Outsourced - Attestation',
  INHERITED = 'Inherited',
  NOT_APPLICABLE = 'Not Applicable',
  TMP_OUTSOURCED = 'Outsourced',
}

export const ControlResponsibilityOptions: Record<
  CONTROL_RESPONSIBILITY,
  string
> = Object.freeze({
  [CONTROL_RESPONSIBILITY.DIRECT_RESPONSIBILITY]:
    ControlResponsibility.DIRECT_RESPONSIBILITY,
  [CONTROL_RESPONSIBILITY.INHERITED]: ControlResponsibility.INHERITED,
  [CONTROL_RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION]:
    ControlResponsibility.OUTSOURCED_NO_ATTESTATION,
  [CONTROL_RESPONSIBILITY.OUTSOURCED_ATTESTATION]:
    ControlResponsibility.OUTSOURCED_ATTESTATION,
  [CONTROL_RESPONSIBILITY.NOT_APPLICABLE]: ControlResponsibility.NOT_APPLICABLE,
  [CONTROL_RESPONSIBILITY.TMP_OUTSOURCED]: ControlResponsibility.TMP_OUTSOURCED,
})

export const ControlResponsibilityCopy: Record<CONTROL_RESPONSIBILITY, string> =
  Object.freeze({
    [CONTROL_RESPONSIBILITY.DIRECT_RESPONSIBILITY]: 'a direct responsibility',
    [CONTROL_RESPONSIBILITY.INHERITED]: 'an inherited responsibility',
    [CONTROL_RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION]:
      'outsourced to a vendor with no attestation',
    [CONTROL_RESPONSIBILITY.OUTSOURCED_ATTESTATION]:
      'outsourced to a vendor with an attestation',
    [CONTROL_RESPONSIBILITY.NOT_APPLICABLE]: 'not applicable',
    [CONTROL_RESPONSIBILITY.TMP_OUTSOURCED]: 'outsourced',
  })

export const ControlResponsibilityToOptions: Record<
  string,
  CONTROL_RESPONSIBILITY
> = Object.freeze({
  [ControlResponsibility.DIRECT_RESPONSIBILITY]:
    CONTROL_RESPONSIBILITY.DIRECT_RESPONSIBILITY,
  [ControlResponsibility.OUTSOURCED_NO_ATTESTATION]:
    CONTROL_RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION,
  [ControlResponsibility.OUTSOURCED_ATTESTATION]:
    CONTROL_RESPONSIBILITY.OUTSOURCED_ATTESTATION,
  [ControlResponsibility.INHERITED]: CONTROL_RESPONSIBILITY.INHERITED,
  [ControlResponsibility.NOT_APPLICABLE]: CONTROL_RESPONSIBILITY.NOT_APPLICABLE,
})

export const ControlResponsibilityDropdownOptions = [
  {
    key: CONTROL_RESPONSIBILITY.DIRECT_RESPONSIBILITY,
    label:
      ControlResponsibilityOptions[
        CONTROL_RESPONSIBILITY.DIRECT_RESPONSIBILITY
      ],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: CONTROL_RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION,
    label:
      ControlResponsibilityOptions[
        CONTROL_RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION
      ],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: CONTROL_RESPONSIBILITY.OUTSOURCED_ATTESTATION,
    label:
      ControlResponsibilityOptions[
        CONTROL_RESPONSIBILITY.OUTSOURCED_ATTESTATION
      ],
    colors: {
      text: palette.neutral.white,
      textHover: palette.blue['50'],
      background: palette.neutral['400'],
      backgroundHover: palette.neutral['900'],
    },
  },
  {
    key: CONTROL_RESPONSIBILITY.INHERITED,
    label: ControlResponsibilityOptions[CONTROL_RESPONSIBILITY.INHERITED],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: CONTROL_RESPONSIBILITY.NOT_APPLICABLE,
    label: ControlResponsibilityOptions[CONTROL_RESPONSIBILITY.NOT_APPLICABLE],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: CONTROL_RESPONSIBILITY.TMP_OUTSOURCED,
    label: ControlResponsibilityOptions[CONTROL_RESPONSIBILITY.TMP_OUTSOURCED],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
]
