/**
 * @fileoverview gRPC-Web generated client stub for attachment
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: attachment/attachment.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var common_model_pb = require('../common/model_pb.js')

var common_time_pb = require('../common/time_pb.js')

var common_payload_pb = require('../common/payload_pb.js')
const proto = {};
proto.attachment = require('./attachment_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.attachment.AttachmentClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.attachment.AttachmentPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetChangeLogEntriesRequest,
 *   !proto.attachment.LogEntries>}
 */
const methodDescriptor_Attachment_GetChangeLogEntries = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetChangeLogEntries',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetChangeLogEntriesRequest,
  proto.attachment.LogEntries,
  /**
   * @param {!proto.attachment.GetChangeLogEntriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.LogEntries.deserializeBinary
);


/**
 * @param {!proto.attachment.GetChangeLogEntriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.LogEntries)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.LogEntries>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getChangeLogEntries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetChangeLogEntries',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeLogEntries,
      callback);
};


/**
 * @param {!proto.attachment.GetChangeLogEntriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.LogEntries>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getChangeLogEntries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetChangeLogEntries',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeLogEntries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.TimeQuery,
 *   !proto.attachment.LogEntries>}
 */
const methodDescriptor_Attachment_GetChangeSince = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetChangeSince',
  grpc.web.MethodType.UNARY,
  proto.attachment.TimeQuery,
  proto.attachment.LogEntries,
  /**
   * @param {!proto.attachment.TimeQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.LogEntries.deserializeBinary
);


/**
 * @param {!proto.attachment.TimeQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.LogEntries)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.LogEntries>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getChangeSince =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetChangeSince',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeSince,
      callback);
};


/**
 * @param {!proto.attachment.TimeQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.LogEntries>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getChangeSince =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetChangeSince',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeSince);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.TimeQuery,
 *   !proto.attachment.LogEntryCount>}
 */
const methodDescriptor_Attachment_GetChangeCountSince = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetChangeCountSince',
  grpc.web.MethodType.UNARY,
  proto.attachment.TimeQuery,
  proto.attachment.LogEntryCount,
  /**
   * @param {!proto.attachment.TimeQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.LogEntryCount.deserializeBinary
);


/**
 * @param {!proto.attachment.TimeQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.LogEntryCount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.LogEntryCount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getChangeCountSince =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetChangeCountSince',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeCountSince,
      callback);
};


/**
 * @param {!proto.attachment.TimeQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.LogEntryCount>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getChangeCountSince =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetChangeCountSince',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeCountSince);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.ModelTypeQuery,
 *   !proto.attachment.IDCounts>}
 */
const methodDescriptor_Attachment_GetChangeCounts = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetChangeCounts',
  grpc.web.MethodType.UNARY,
  proto.attachment.ModelTypeQuery,
  proto.attachment.IDCounts,
  /**
   * @param {!proto.attachment.ModelTypeQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.IDCounts.deserializeBinary
);


/**
 * @param {!proto.attachment.ModelTypeQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.IDCounts)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.IDCounts>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getChangeCounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetChangeCounts',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeCounts,
      callback);
};


/**
 * @param {!proto.attachment.ModelTypeQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.IDCounts>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getChangeCounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetChangeCounts',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeCounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.IDsQuery,
 *   !proto.attachment.LogEntries>}
 */
const methodDescriptor_Attachment_GetChangesBySubjectIDs = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetChangesBySubjectIDs',
  grpc.web.MethodType.UNARY,
  proto.attachment.IDsQuery,
  proto.attachment.LogEntries,
  /**
   * @param {!proto.attachment.IDsQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.LogEntries.deserializeBinary
);


/**
 * @param {!proto.attachment.IDsQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.LogEntries)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.LogEntries>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getChangesBySubjectIDs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetChangesBySubjectIDs',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangesBySubjectIDs,
      callback);
};


/**
 * @param {!proto.attachment.IDsQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.LogEntries>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getChangesBySubjectIDs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetChangesBySubjectIDs',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangesBySubjectIDs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetChangeByOIDRequest,
 *   !proto.attachment.LogEntry>}
 */
const methodDescriptor_Attachment_GetChangeByOID = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetChangeByOID',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetChangeByOIDRequest,
  proto.attachment.LogEntry,
  /**
   * @param {!proto.attachment.GetChangeByOIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.LogEntry.deserializeBinary
);


/**
 * @param {!proto.attachment.GetChangeByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.LogEntry)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.LogEntry>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getChangeByOID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetChangeByOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeByOID,
      callback);
};


/**
 * @param {!proto.attachment.GetChangeByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.LogEntry>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getChangeByOID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetChangeByOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetChangeByOID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.LookUpChangeLogEntryRequest,
 *   !proto.attachment.LookUpChangeLogEntryResponse>}
 */
const methodDescriptor_Attachment_LookUpChangeLogEntry = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/LookUpChangeLogEntry',
  grpc.web.MethodType.UNARY,
  proto.attachment.LookUpChangeLogEntryRequest,
  proto.attachment.LookUpChangeLogEntryResponse,
  /**
   * @param {!proto.attachment.LookUpChangeLogEntryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.LookUpChangeLogEntryResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.LookUpChangeLogEntryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.LookUpChangeLogEntryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.lookUpChangeLogEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/LookUpChangeLogEntry',
      request,
      metadata || {},
      methodDescriptor_Attachment_LookUpChangeLogEntry,
      callback);
};


/**
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.LookUpChangeLogEntryResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.lookUpChangeLogEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/LookUpChangeLogEntry',
      request,
      metadata || {},
      methodDescriptor_Attachment_LookUpChangeLogEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.DocumentTypeQuery,
 *   !proto.attachment.IDCounts>}
 */
const methodDescriptor_Attachment_GetDocumentCounts = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetDocumentCounts',
  grpc.web.MethodType.UNARY,
  proto.attachment.DocumentTypeQuery,
  proto.attachment.IDCounts,
  /**
   * @param {!proto.attachment.DocumentTypeQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.IDCounts.deserializeBinary
);


/**
 * @param {!proto.attachment.DocumentTypeQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.IDCounts)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.IDCounts>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getDocumentCounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentCounts',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentCounts,
      callback);
};


/**
 * @param {!proto.attachment.DocumentTypeQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.IDCounts>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getDocumentCounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentCounts',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentCounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.ModelTypeQuery,
 *   !proto.attachment.Documents>}
 */
const methodDescriptor_Attachment_GetDocumentsBySubjectType = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetDocumentsBySubjectType',
  grpc.web.MethodType.UNARY,
  proto.attachment.ModelTypeQuery,
  proto.attachment.Documents,
  /**
   * @param {!proto.attachment.ModelTypeQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Documents.deserializeBinary
);


/**
 * @param {!proto.attachment.ModelTypeQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Documents)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Documents>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getDocumentsBySubjectType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentsBySubjectType',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentsBySubjectType,
      callback);
};


/**
 * @param {!proto.attachment.ModelTypeQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Documents>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getDocumentsBySubjectType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentsBySubjectType',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentsBySubjectType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.IDsQuery,
 *   !proto.attachment.Documents>}
 */
const methodDescriptor_Attachment_GetDocumentsBySubjectIDs = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetDocumentsBySubjectIDs',
  grpc.web.MethodType.UNARY,
  proto.attachment.IDsQuery,
  proto.attachment.Documents,
  /**
   * @param {!proto.attachment.IDsQuery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Documents.deserializeBinary
);


/**
 * @param {!proto.attachment.IDsQuery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Documents)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Documents>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getDocumentsBySubjectIDs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentsBySubjectIDs',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentsBySubjectIDs,
      callback);
};


/**
 * @param {!proto.attachment.IDsQuery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Documents>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getDocumentsBySubjectIDs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentsBySubjectIDs',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentsBySubjectIDs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetPolicyDocumentsByPolicyIDRequest,
 *   !proto.attachment.Documents>}
 */
const methodDescriptor_Attachment_GetPolicyDocumentsByPolicyID = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetPolicyDocumentsByPolicyID',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetPolicyDocumentsByPolicyIDRequest,
  proto.attachment.Documents,
  /**
   * @param {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Documents.deserializeBinary
);


/**
 * @param {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Documents)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Documents>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getPolicyDocumentsByPolicyID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetPolicyDocumentsByPolicyID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetPolicyDocumentsByPolicyID,
      callback);
};


/**
 * @param {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Documents>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getPolicyDocumentsByPolicyID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetPolicyDocumentsByPolicyID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetPolicyDocumentsByPolicyID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest,
 *   !proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse>}
 */
const methodDescriptor_Attachment_GetDocumentOIDsByAuditPeriodOID = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetDocumentOIDsByAuditPeriodOID',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest,
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse,
  /**
   * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getDocumentOIDsByAuditPeriodOID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentOIDsByAuditPeriodOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentOIDsByAuditPeriodOID,
      callback);
};


/**
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getDocumentOIDsByAuditPeriodOID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentOIDsByAuditPeriodOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentOIDsByAuditPeriodOID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetDocumentByOIDRequest,
 *   !proto.attachment.Document>}
 */
const methodDescriptor_Attachment_GetDocumentByOID = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetDocumentByOID',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetDocumentByOIDRequest,
  proto.attachment.Document,
  /**
   * @param {!proto.attachment.GetDocumentByOIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Document.deserializeBinary
);


/**
 * @param {!proto.attachment.GetDocumentByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getDocumentByOID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentByOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentByOID,
      callback);
};


/**
 * @param {!proto.attachment.GetDocumentByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Document>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getDocumentByOID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetDocumentByOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetDocumentByOID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetEvidenceBodyByOIDRequest,
 *   !proto.attachment.GetEvidenceBodyByOIDResponse>}
 */
const methodDescriptor_Attachment_GetEvidenceBodyByOID = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetEvidenceBodyByOID',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetEvidenceBodyByOIDRequest,
  proto.attachment.GetEvidenceBodyByOIDResponse,
  /**
   * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetEvidenceBodyByOIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetEvidenceBodyByOIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getEvidenceBodyByOID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetEvidenceBodyByOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetEvidenceBodyByOID,
      callback);
};


/**
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetEvidenceBodyByOIDResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getEvidenceBodyByOID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetEvidenceBodyByOID',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetEvidenceBodyByOID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetLatestEvidenceRequest,
 *   !proto.attachment.GetLatestEvidenceResponse>}
 */
const methodDescriptor_Attachment_GetLatestEvidence = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetLatestEvidence',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetLatestEvidenceRequest,
  proto.attachment.GetLatestEvidenceResponse,
  /**
   * @param {!proto.attachment.GetLatestEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetLatestEvidenceResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.GetLatestEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetLatestEvidenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetLatestEvidenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getLatestEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetLatestEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetLatestEvidence,
      callback);
};


/**
 * @param {!proto.attachment.GetLatestEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetLatestEvidenceResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getLatestEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetLatestEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetLatestEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetExclusionsRequest,
 *   !proto.attachment.GetExclusionsResponse>}
 */
const methodDescriptor_Attachment_GetExclusions = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetExclusions',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetExclusionsRequest,
  proto.attachment.GetExclusionsResponse,
  /**
   * @param {!proto.attachment.GetExclusionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetExclusionsResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.GetExclusionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetExclusionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetExclusionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getExclusions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetExclusions',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetExclusions,
      callback);
};


/**
 * @param {!proto.attachment.GetExclusionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetExclusionsResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getExclusions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetExclusions',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetExclusions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.ListEvidenceRequest,
 *   !proto.attachment.ListEvidenceResponse>}
 */
const methodDescriptor_Attachment_ListEvidence = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/ListEvidence',
  grpc.web.MethodType.UNARY,
  proto.attachment.ListEvidenceRequest,
  proto.attachment.ListEvidenceResponse,
  /**
   * @param {!proto.attachment.ListEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.ListEvidenceResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.ListEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.ListEvidenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.ListEvidenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.listEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/ListEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_ListEvidence,
      callback);
};


/**
 * @param {!proto.attachment.ListEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.ListEvidenceResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.listEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/ListEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_ListEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.attachment.EvidenceGenerators>}
 */
const methodDescriptor_Attachment_ListEvidenceGenerators = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/ListEvidenceGenerators',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.attachment.EvidenceGenerators,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.EvidenceGenerators.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.EvidenceGenerators)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.EvidenceGenerators>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.listEvidenceGenerators =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/ListEvidenceGenerators',
      request,
      metadata || {},
      methodDescriptor_Attachment_ListEvidenceGenerators,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.EvidenceGenerators>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.listEvidenceGenerators =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/ListEvidenceGenerators',
      request,
      metadata || {},
      methodDescriptor_Attachment_ListEvidenceGenerators);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.ModelEvidenceGenerator,
 *   !proto.attachment.ModelEvidenceGenerator>}
 */
const methodDescriptor_Attachment_UpdateModelEvidenceGenerator = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/UpdateModelEvidenceGenerator',
  grpc.web.MethodType.UNARY,
  proto.attachment.ModelEvidenceGenerator,
  proto.attachment.ModelEvidenceGenerator,
  /**
   * @param {!proto.attachment.ModelEvidenceGenerator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.ModelEvidenceGenerator.deserializeBinary
);


/**
 * @param {!proto.attachment.ModelEvidenceGenerator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.ModelEvidenceGenerator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.ModelEvidenceGenerator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.updateModelEvidenceGenerator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/UpdateModelEvidenceGenerator',
      request,
      metadata || {},
      methodDescriptor_Attachment_UpdateModelEvidenceGenerator,
      callback);
};


/**
 * @param {!proto.attachment.ModelEvidenceGenerator} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.ModelEvidenceGenerator>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.updateModelEvidenceGenerator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/UpdateModelEvidenceGenerator',
      request,
      metadata || {},
      methodDescriptor_Attachment_UpdateModelEvidenceGenerator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetModelEvidenceGeneratorRequest,
 *   !proto.attachment.ModelEvidenceGenerator>}
 */
const methodDescriptor_Attachment_GetOrCreateModelEvidenceGenerator = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetOrCreateModelEvidenceGenerator',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetModelEvidenceGeneratorRequest,
  proto.attachment.ModelEvidenceGenerator,
  /**
   * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.ModelEvidenceGenerator.deserializeBinary
);


/**
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.ModelEvidenceGenerator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.ModelEvidenceGenerator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getOrCreateModelEvidenceGenerator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetOrCreateModelEvidenceGenerator',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetOrCreateModelEvidenceGenerator,
      callback);
};


/**
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.ModelEvidenceGenerator>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getOrCreateModelEvidenceGenerator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetOrCreateModelEvidenceGenerator',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetOrCreateModelEvidenceGenerator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.ListConnectedEvidenceGeneratorsRequest,
 *   !proto.attachment.EvidenceGenerators>}
 */
const methodDescriptor_Attachment_ListConnectedEvidenceGenerators = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/ListConnectedEvidenceGenerators',
  grpc.web.MethodType.UNARY,
  proto.attachment.ListConnectedEvidenceGeneratorsRequest,
  proto.attachment.EvidenceGenerators,
  /**
   * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.EvidenceGenerators.deserializeBinary
);


/**
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.EvidenceGenerators)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.EvidenceGenerators>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.listConnectedEvidenceGenerators =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/ListConnectedEvidenceGenerators',
      request,
      metadata || {},
      methodDescriptor_Attachment_ListConnectedEvidenceGenerators,
      callback);
};


/**
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.EvidenceGenerators>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.listConnectedEvidenceGenerators =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/ListConnectedEvidenceGenerators',
      request,
      metadata || {},
      methodDescriptor_Attachment_ListConnectedEvidenceGenerators);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.AddDocumentRequest,
 *   !proto.attachment.Document>}
 */
const methodDescriptor_Attachment_AddDocument = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/AddDocument',
  grpc.web.MethodType.UNARY,
  proto.attachment.AddDocumentRequest,
  proto.attachment.Document,
  /**
   * @param {!proto.attachment.AddDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Document.deserializeBinary
);


/**
 * @param {!proto.attachment.AddDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.addDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/AddDocument',
      request,
      metadata || {},
      methodDescriptor_Attachment_AddDocument,
      callback);
};


/**
 * @param {!proto.attachment.AddDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Document>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.addDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/AddDocument',
      request,
      metadata || {},
      methodDescriptor_Attachment_AddDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.LinkDocumentsRequest,
 *   !proto.attachment.Documents>}
 */
const methodDescriptor_Attachment_LinkDocuments = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/LinkDocuments',
  grpc.web.MethodType.UNARY,
  proto.attachment.LinkDocumentsRequest,
  proto.attachment.Documents,
  /**
   * @param {!proto.attachment.LinkDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Documents.deserializeBinary
);


/**
 * @param {!proto.attachment.LinkDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Documents)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Documents>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.linkDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/LinkDocuments',
      request,
      metadata || {},
      methodDescriptor_Attachment_LinkDocuments,
      callback);
};


/**
 * @param {!proto.attachment.LinkDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Documents>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.linkDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/LinkDocuments',
      request,
      metadata || {},
      methodDescriptor_Attachment_LinkDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.AddEvidenceRequest,
 *   !proto.attachment.Document>}
 */
const methodDescriptor_Attachment_AddEvidence = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/AddEvidence',
  grpc.web.MethodType.UNARY,
  proto.attachment.AddEvidenceRequest,
  proto.attachment.Document,
  /**
   * @param {!proto.attachment.AddEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Document.deserializeBinary
);


/**
 * @param {!proto.attachment.AddEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.addEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/AddEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_AddEvidence,
      callback);
};


/**
 * @param {!proto.attachment.AddEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Document>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.addEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/AddEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_AddEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.BulkAddEvidenceRequest,
 *   !proto.attachment.Documents>}
 */
const methodDescriptor_Attachment_BulkAddEvidence = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/BulkAddEvidence',
  grpc.web.MethodType.UNARY,
  proto.attachment.BulkAddEvidenceRequest,
  proto.attachment.Documents,
  /**
   * @param {!proto.attachment.BulkAddEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Documents.deserializeBinary
);


/**
 * @param {!proto.attachment.BulkAddEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Documents)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Documents>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.bulkAddEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/BulkAddEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_BulkAddEvidence,
      callback);
};


/**
 * @param {!proto.attachment.BulkAddEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Documents>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.bulkAddEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/BulkAddEvidence',
      request,
      metadata || {},
      methodDescriptor_Attachment_BulkAddEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.UpdateRelevantDateRequest,
 *   !proto.attachment.Document>}
 */
const methodDescriptor_Attachment_UpdateRelevantDate = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/UpdateRelevantDate',
  grpc.web.MethodType.UNARY,
  proto.attachment.UpdateRelevantDateRequest,
  proto.attachment.Document,
  /**
   * @param {!proto.attachment.UpdateRelevantDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Document.deserializeBinary
);


/**
 * @param {!proto.attachment.UpdateRelevantDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.updateRelevantDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/UpdateRelevantDate',
      request,
      metadata || {},
      methodDescriptor_Attachment_UpdateRelevantDate,
      callback);
};


/**
 * @param {!proto.attachment.UpdateRelevantDateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Document>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.updateRelevantDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/UpdateRelevantDate',
      request,
      metadata || {},
      methodDescriptor_Attachment_UpdateRelevantDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.DocumentPeriod,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Attachment_AssociateDocumentToAuditPeriod = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/AssociateDocumentToAuditPeriod',
  grpc.web.MethodType.UNARY,
  proto.attachment.DocumentPeriod,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.attachment.DocumentPeriod} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.attachment.DocumentPeriod} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.associateDocumentToAuditPeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/AssociateDocumentToAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_AssociateDocumentToAuditPeriod,
      callback);
};


/**
 * @param {!proto.attachment.DocumentPeriod} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.associateDocumentToAuditPeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/AssociateDocumentToAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_AssociateDocumentToAuditPeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.DeleteDocumentRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Attachment_DeleteDocument = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/DeleteDocument',
  grpc.web.MethodType.UNARY,
  proto.attachment.DeleteDocumentRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.attachment.DeleteDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.attachment.DeleteDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.deleteDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/DeleteDocument',
      request,
      metadata || {},
      methodDescriptor_Attachment_DeleteDocument,
      callback);
};


/**
 * @param {!proto.attachment.DeleteDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.deleteDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/DeleteDocument',
      request,
      metadata || {},
      methodDescriptor_Attachment_DeleteDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.Caption,
 *   !proto.attachment.Document>}
 */
const methodDescriptor_Attachment_SetCaption = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/SetCaption',
  grpc.web.MethodType.UNARY,
  proto.attachment.Caption,
  proto.attachment.Document,
  /**
   * @param {!proto.attachment.Caption} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.Document.deserializeBinary
);


/**
 * @param {!proto.attachment.Caption} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.setCaption =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/SetCaption',
      request,
      metadata || {},
      methodDescriptor_Attachment_SetCaption,
      callback);
};


/**
 * @param {!proto.attachment.Caption} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.Document>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.setCaption =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/SetCaption',
      request,
      metadata || {},
      methodDescriptor_Attachment_SetCaption);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.attachment.AuditPeriods>}
 */
const methodDescriptor_Attachment_GetActiveAuditPeriods = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetActiveAuditPeriods',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.attachment.AuditPeriods,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.AuditPeriods.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.AuditPeriods)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.AuditPeriods>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getActiveAuditPeriods =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetActiveAuditPeriods',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetActiveAuditPeriods,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.AuditPeriods>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getActiveAuditPeriods =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetActiveAuditPeriods',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetActiveAuditPeriods);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.AuditPeriod,
 *   !proto.attachment.AuditPeriod>}
 */
const methodDescriptor_Attachment_CreateAuditPeriod = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/CreateAuditPeriod',
  grpc.web.MethodType.UNARY,
  proto.attachment.AuditPeriod,
  proto.attachment.AuditPeriod,
  /**
   * @param {!proto.attachment.AuditPeriod} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.AuditPeriod.deserializeBinary
);


/**
 * @param {!proto.attachment.AuditPeriod} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.AuditPeriod)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.AuditPeriod>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.createAuditPeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/CreateAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_CreateAuditPeriod,
      callback);
};


/**
 * @param {!proto.attachment.AuditPeriod} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.AuditPeriod>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.createAuditPeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/CreateAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_CreateAuditPeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.CloseAudit,
 *   !proto.attachment.AuditPeriod>}
 */
const methodDescriptor_Attachment_CloseAuditPeriod = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/CloseAuditPeriod',
  grpc.web.MethodType.UNARY,
  proto.attachment.CloseAudit,
  proto.attachment.AuditPeriod,
  /**
   * @param {!proto.attachment.CloseAudit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.AuditPeriod.deserializeBinary
);


/**
 * @param {!proto.attachment.CloseAudit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.AuditPeriod)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.AuditPeriod>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.closeAuditPeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/CloseAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_CloseAuditPeriod,
      callback);
};


/**
 * @param {!proto.attachment.CloseAudit} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.AuditPeriod>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.closeAuditPeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/CloseAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_CloseAuditPeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.CompleteAudit,
 *   !proto.attachment.AuditPeriod>}
 */
const methodDescriptor_Attachment_CompleteAuditPeriod = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/CompleteAuditPeriod',
  grpc.web.MethodType.UNARY,
  proto.attachment.CompleteAudit,
  proto.attachment.AuditPeriod,
  /**
   * @param {!proto.attachment.CompleteAudit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.AuditPeriod.deserializeBinary
);


/**
 * @param {!proto.attachment.CompleteAudit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.AuditPeriod)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.AuditPeriod>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.completeAuditPeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/CompleteAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_CompleteAuditPeriod,
      callback);
};


/**
 * @param {!proto.attachment.CompleteAudit} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.AuditPeriod>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.completeAuditPeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/CompleteAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_CompleteAuditPeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.DeleteAuditPeriodRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Attachment_DeleteAuditPeriod = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/DeleteAuditPeriod',
  grpc.web.MethodType.UNARY,
  proto.attachment.DeleteAuditPeriodRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.attachment.DeleteAuditPeriodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.attachment.DeleteAuditPeriodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.deleteAuditPeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/DeleteAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_DeleteAuditPeriod,
      callback);
};


/**
 * @param {!proto.attachment.DeleteAuditPeriodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.deleteAuditPeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/DeleteAuditPeriod',
      request,
      metadata || {},
      methodDescriptor_Attachment_DeleteAuditPeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetEvidenceDiscoveryRequest,
 *   !proto.attachment.GetEvidenceDiscoveryResponse>}
 */
const methodDescriptor_Attachment_GetEvidenceDiscovery = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetEvidenceDiscovery',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetEvidenceDiscoveryRequest,
  proto.attachment.GetEvidenceDiscoveryResponse,
  /**
   * @param {!proto.attachment.GetEvidenceDiscoveryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetEvidenceDiscoveryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetEvidenceDiscoveryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getEvidenceDiscovery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetEvidenceDiscovery',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetEvidenceDiscovery,
      callback);
};


/**
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetEvidenceDiscoveryResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getEvidenceDiscovery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetEvidenceDiscovery',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetEvidenceDiscovery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.GetPolicyAcknowledgementFormRequest,
 *   !proto.attachment.GetPolicyAcknowledgementFormResponse>}
 */
const methodDescriptor_Attachment_GetPolicyAcknowledgementForm = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetPolicyAcknowledgementForm',
  grpc.web.MethodType.UNARY,
  proto.attachment.GetPolicyAcknowledgementFormRequest,
  proto.attachment.GetPolicyAcknowledgementFormResponse,
  /**
   * @param {!proto.attachment.GetPolicyAcknowledgementFormRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetPolicyAcknowledgementFormResponse.deserializeBinary
);


/**
 * @param {!proto.attachment.GetPolicyAcknowledgementFormRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetPolicyAcknowledgementFormResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetPolicyAcknowledgementFormResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getPolicyAcknowledgementForm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetPolicyAcknowledgementForm',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetPolicyAcknowledgementForm,
      callback);
};


/**
 * @param {!proto.attachment.GetPolicyAcknowledgementFormRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetPolicyAcknowledgementFormResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getPolicyAcknowledgementForm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetPolicyAcknowledgementForm',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetPolicyAcknowledgementForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.attachment.GetRiskRegisterCSVResponse>}
 */
const methodDescriptor_Attachment_GetRiskRegisterCSVDownload = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/GetRiskRegisterCSVDownload',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.attachment.GetRiskRegisterCSVResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.attachment.GetRiskRegisterCSVResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.attachment.GetRiskRegisterCSVResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.attachment.GetRiskRegisterCSVResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.getRiskRegisterCSVDownload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/GetRiskRegisterCSVDownload',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetRiskRegisterCSVDownload,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.attachment.GetRiskRegisterCSVResponse>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.getRiskRegisterCSVDownload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/GetRiskRegisterCSVDownload',
      request,
      metadata || {},
      methodDescriptor_Attachment_GetRiskRegisterCSVDownload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.attachment.DeleteControlEvidencesByCaptionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Attachment_DeleteControlEvidencesByCaption = new grpc.web.MethodDescriptor(
  '/attachment.Attachment/DeleteControlEvidencesByCaption',
  grpc.web.MethodType.UNARY,
  proto.attachment.DeleteControlEvidencesByCaptionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.attachment.AttachmentClient.prototype.deleteControlEvidencesByCaption =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/attachment.Attachment/DeleteControlEvidencesByCaption',
      request,
      metadata || {},
      methodDescriptor_Attachment_DeleteControlEvidencesByCaption,
      callback);
};


/**
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.attachment.AttachmentPromiseClient.prototype.deleteControlEvidencesByCaption =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/attachment.Attachment/DeleteControlEvidencesByCaption',
      request,
      metadata || {},
      methodDescriptor_Attachment_DeleteControlEvidencesByCaption);
};


module.exports = proto.attachment;

