import React, { Dispatch, SetStateAction } from 'react'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { SmartControlTestRecord } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { GridColumnFlexEnd } from 'src/components/ModalForms/ModalForm.styles'
import { ControlModelId } from 'src/pages/Controls/IndexItem/ControlsListItem.components'
import { PERMISSIONS } from 'src/config/roleConfig'
import { GridColumn, GridRow } from '../../Grid'
import { Title } from '../../Text/Title'
import { CheckBoxInput } from '../../Inputs/CheckBox/CheckBoxInput'
import { ControlPolicyInfo } from '../../../../pages/Controls/IndexItem/ControlPolicyInfo'
import { DepartmentChip } from '../../../../pages/Controls/DepartmentChip'

export interface SelectControlsRowItemBaseProps {
  control: Control
  selectedControlIds: string[]
  setSelectedControlIds: Dispatch<SetStateAction<string[]>>
}

interface SelectControlsRowItemProps extends SelectControlsRowItemBaseProps {
  customPolicyElement?: JSX.Element
  customDepartmentElement?: JSX.Element
  objectiveAsId: boolean
  controlTestResult?: SmartControlTestRecord
  hideCheckbox?: boolean
  optionalColumn?: JSX.Element
}

const selectControlsRowItemDefaultProps = {
  objectiveAsId: false,
}

export const SelectControlsRowItem = ({
  control,
  selectedControlIds,
  setSelectedControlIds,
  customPolicyElement,
  customDepartmentElement,
  objectiveAsId,
  hideCheckbox,
  optionalColumn,
}: SelectControlsRowItemProps): JSX.Element => {
  const selectedControls = new Set(selectedControlIds)
  const controlId = objectiveAsId ? control.getObjective() : control.getId()
  const name = control.getName()
  const departmentId = control.getDepartmentId()
  const policyId = control.getPolicyId()
  const customId = control.getModelId()

  const onChangeHandler = () => {
    selectedControls.has(controlId)
      ? selectedControls.delete(controlId)
      : selectedControls.add(controlId)
    setSelectedControlIds(Array.from(selectedControls))
  }

  return (
    <GridRow id={`select-controls-item-${controlId}`}>
      {!hideCheckbox && (
        <GridColumn>
          <CheckBoxInput
            id={controlId}
            checked={selectedControls.has(controlId)}
            onClick={(e) => e.stopPropagation()}
            onChange={onChangeHandler}
            requiredPermissions={[PERMISSIONS.AUDITOR_ACTION]}
          />
        </GridColumn>
      )}
      <GridColumn>
        {customDepartmentElement || (
          <DepartmentChip departmentId={departmentId} />
        )}
      </GridColumn>
      <GridColumn>
        <ControlModelId modelId={customId} />
      </GridColumn>
      <GridColumn
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <div>
          {customPolicyElement || <ControlPolicyInfo policyId={policyId} />}
          <Title name={name} />
        </div>
      </GridColumn>
      {optionalColumn && (
        <GridColumnFlexEnd>{optionalColumn}</GridColumnFlexEnd>
      )}
    </GridRow>
  )
}

SelectControlsRowItem.defaultProps = selectControlsRowItemDefaultProps
